<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-07-27 13:15:56
 * @LastEditors: ------
 * @LastEditTime: 2021-07-27 13:48:57
-->
<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go" class="cursor"> 首页 > </span>
      </template>
      <template #two>
        <span @click="go" class="cursor"> 客户管理 > </span>
      </template>
      <template v-slot:slotCon>
        <div class="personal_detail">
          <div class="personal_detail_info base_666_color">
            <div class="pay_top">
              <img src="../image/info.png" alt="" />
              <span class="base_333_color ">本人信息</span>
            </div>
            <div class="base_info ">
              <span>用户姓名：111111111111</span>
              <span>用户手机号：222222222222222</span>
            </div>
            <div class="base_info ">
              <span>用户身份证：333333333333333</span>
              <span>所在城市：4444444444444</span>
            </div>
            <div class="base_info ">
              <span>居住地址：5555555555555555555</span>
              <!-- <span></span> -->
            </div>
          </div>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn">
      <template #one>
        <button @click="go" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="go" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽

export default {
  data() {
    return {};
  },
  components: { TitleNav, ebutton },
};
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
/* ---------------------------------------------- */
.three {
  height: 2500px;
}
.pay_top {
  padding-bottom: 34px;
  border-bottom: 1px solid #e2e2e2;
}
.pay_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.pay_top span {
  font-size: 22px;
}
.base_info {
  font-size: 20px;
  display: flex;
  height: 50px;
  line-height: 50px;
  margin: 16px 0 16px 40px;
}
.base_info span:nth-child(1) {
  flex: 2;
}
.base_info span:nth-child(2) {
  flex: 4;
}
</style>
